// types
import type {
  Enrollment,
  Student,
  Tutor,
  TutoringHistory
} from '@revolutionprep/types'

// nuxt
import { useNuxtApp } from '#app'

// stores
import { useScheduleSessionsV2Store } from '@/store/schedule-sessions-v2'

export function useScheduleSessionsV2 () {
  /**
   * nuxt app
   * ==================================================================
   */
  const { $actor } = useNuxtApp()

  /**
   * route & router
   * ==================================================================
   */
  const route = useRoute()
  const router = useRouter()

  /**
   * stores
   * ==================================================================
   */
  const enrollmentStore = useEnrollmentStore()
  const { enrollment } = storeToRefs(enrollmentStore)

  const organizationStore = useOrganizationStore()
  const { organizationInfo: company } = storeToRefs(organizationStore)

  const scheduleSessionsV2Store = useScheduleSessionsV2Store()
  const {
    sessionSubject,
    sessionSubjectId,
    sessionDuration,
    sessionFrequency
  } = storeToRefs(scheduleSessionsV2Store)

  /**
   * state
   * ==================================================================
   */
  const enrollmentShowIncludes = ref(
    'active_affiliate_reservations.tutor_package,brand,course.brand.subjects,course.subject,suggested_subject'
  )

  /**
   * computed
   * ==================================================================
   */
  // actor
  const actor = computed(() => {
    return $actor.core.actor.value as Student
  })

  const actorId = computed(() => {
    return $actor.core.actorId.value
  })

  // auth
  const isLoggedIn = computed(() => {
    return Boolean($actor.core.isLoggedIn.value && actor.value)
  })

  // enrollment
  const enrollmentId = computed(() => {
    return Number(route.params.enrollmentId)
  })

  const tutoringEnrollments = computed(() => {
    return enrollmentStore.getTutoringEnrollments()
  })

  // tutors
  const tutorCount = computed(() => {
    return tutoringEnrollments.value?.reduce((
      count: number,
      enrollment: Enrollment
    ) => {
      count += getEnrollmentTutors(enrollment).length
      return count
    }, 0)
  })

  const tutorCountLabel = computed(() => {
    return tutorCount.value === 1 ? 'tutor' : 'tutors'
  })

  // schedule sessions steps
  const scheduleSessionsStep = computed(() => {
    return $actor.core.storage.getState<number>('scheduleSessionsStep')
  })

  const steps = computed(() => {
    return [
      {
        routePath: `/schedule-sessions/${enrollmentId.value}/subject`,
        stepNumber: 1,
        stepTitle: 'Subject'
      },
      {
        routePath: `/schedule-sessions/${enrollmentId.value}/availability`,
        stepNumber: 2,
        stepTitle: 'Availability'
      },
      {
        stepNumber: 3,
        routePath: `/schedule-sessions/${enrollmentId.value}/confirm`,
        stepTitle: 'Schedule'
      }
    ]
  })

  // timezone
  const timezone = computed(() => {
    return new Date().toLocaleString(
      'en',
      { timeZoneName: 'short' }
    ).split(' ').pop()
  })

  // session
  const sessionSubjectName = computed(() => {
    return storedSessionSubject.value?.name || ''
  })

  /**
   * methods
   * ==================================================================
   */
  // enrollments
  function getEnrollmentTutors (enrollment: Enrollment) {
    return enrollment?.tutoringHistories?.reduce((
      tutorArray: Partial<Tutor>[],
      tutoringHistory: TutoringHistory
    ) => {
      if (tutoringHistory.tutor) {
        tutorArray.push(tutoringHistory.tutor)
      }
      return tutorArray
    }, []) || []
  }

  function toPrevStep (currentStep: number) {
    switch (currentStep) {
      case 1:
        return router.replace({
          path: '/schedule-sessions'
        })
      case 2:
        return router.replace({
          path: steps.value[0].routePath
        })
      case 3:
        return router.replace({
          path: steps.value[1].routePath
        })
    }
  }

  /**
   * storage
   * ==================================================================
   */
  const storedSessionDuration = computed(() => {
    return $actor.core.storage.getUniversal(
      `sessionDuration-${actorId.value}`
    ) as number
  })

  const storedSessionFrequency = computed(() => {
    return $actor.core.storage.getUniversal(
      `sessionFrequency-${actorId.value}`
    ) as number
  })

  const storedSessionSubject = computed(() => {
    return $actor.core.storage.getUniversal(
      `sessionSubject-${actorId.value}`
    )
  })

  const storedSessionSubjectId = computed(() => {
    return $actor.core.storage.getUniversal(
      `sessionSubjectId-${actorId.value}`
    ) as number
  })

  function clearScheduleLocalStorage () {
    $actor.core.storage.setUniversal(
      `sessionSubject-${actorId.value}`,
      undefined
    )
    $actor.core.storage.setUniversal(
      `sessionSubjectId-${actorId.value}`,
      undefined
    )
    $actor.core.storage.setUniversal(
      `sessionDuration-${actorId.value}`,
      undefined
    )
    $actor.core.storage.setUniversal(
      `sessionFrequency-${actorId.value}`,
      undefined
    )
  }

  return {
    actor,
    actorId,
    clearScheduleLocalStorage,
    company,
    enrollment,
    enrollmentId,
    enrollmentShowIncludes,
    getEnrollmentTutors,
    isLoggedIn,
    scheduleSessionsStep,
    sessionDuration,
    sessionFrequency,
    sessionSubject,
    sessionSubjectId,
    sessionSubjectName,
    steps,
    storedSessionDuration,
    storedSessionFrequency,
    storedSessionSubject,
    storedSessionSubjectId,
    timezone,
    toPrevStep,
    tutorCount,
    tutorCountLabel,
    tutoringEnrollments
  }
}
